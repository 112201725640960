import { useDialog } from "@shared-ui/dialog-context";
import { UitkSheet, UitkSheetContent, UitkSheetTransition } from "@egds/react-core/sheet";
import { UitkToolbar } from "@egds/react-core/toolbar";
import { PROPERTY_FILTERS } from "components/flexComponents/PropertyFilters/PropertyFilters";
import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";
import { UitkFloatingFullWidthButton, UitkTertiaryButton, UitkButtonSize } from "@egds/react-core/button";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { withStores } from "stores";
import { PropertyFiltersGroupProps } from "components/flexComponents/PropertyFilters/typings";
import { PROPERTY_FILTERS_COMPONENT_GROUP_MAP } from "components/shared/PropertyFilterComponents/map/PropertyFiltersComponentGroupMap";
import {
  Action,
  FlexTrackingInfo,
  sendImmediateClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { PropertyFiltersStore } from "src/stores/PropertyFiltersStore";
import { observer } from "mobx-react";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { UitkSpacing } from "@egds/react-core/spacing";
import { useInteractiveFilters } from "src/components/flexComponents/PropertyFiltersInteractive/PropertyFiltersInteractiveContext";
import { UitkScrim, UitkScrimType } from "@egds/react-core/scrim";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkText } from "@egds/react-core/text";

const ClearButtonTracked = FlexClickTracker(UitkTertiaryButton);

interface PropertyFiltersDialogProps {
  children: React.ReactNode;
  propertyFilters: PropertyFiltersStore;
  context: ExtendedContextStore;
  daysOffset?: number;
  isExpanded?: boolean;
}

export const PropertyFiltersDialog = withStores(
  "context",
  "propertyFilters"
)(
  observer((props: PropertyFiltersDialogProps) => {
    const { propertyFilters, daysOffset, isExpanded } = props;
    const l10n = useLocalization();
    const track = useClickTracker();
    const redirectToSRP = () =>
      window.location.assign(
        propertyFilters.buildLinkToSRP({ daysOffset, checkForPopularFilterInFilters: isExpanded })
      );
    const applyFilters = useInteractiveFilters();
    const hasApplyFilters = Boolean(applyFilters);
    const [isDialogOpen, dialogActions, FiltersDialogComponent] = useDialog("filters");
    const triggerRef = React.createRef<HTMLElement>();

    const onDismiss = () => {
      propertyFilters.resetFilters();
      dialogActions.closeDialog();
    };

    const onClickClear = () => {
      propertyFilters.resetAllFilters();
    };

    const onSubmit: React.FormEventHandler = (e) => {
      e.preventDefault();

      propertyFilters.defrost();

      const selectedFiltersCount = isExpanded
        ? propertyFilters.getNumberOfSelectedExpandedFilters()
        : propertyFilters.getNumberOfSelectedFilters();

      const flexTracking: FlexTrackingInfo = {
        moduleName: PROPERTY_FILTERS,
        rfrr: `dialog.done.${selectedFiltersCount}`,
        action: Action.CLICK,
      };

      sendImmediateClickstreamTrackEvent(flexTracking, track);

      if (hasApplyFilters) {
        applyFilters!();
        dialogActions.closeDialog();
      } else {
        redirectToSRP();
      }
    };

    const dialogTitle = propertyFilters.hasSort
      ? l10n.formatText("propertyfilters.sortAndFilter")
      : l10n.formatText("propertyfilters.filter");

    return (
      <UitkSheetTransition isVisible={isDialogOpen}>
        <FiltersDialogComponent>
          <UitkSheet triggerRef={triggerRef} isVisible type="full">
            <UitkToolbar
              toolbarTitle={dialogTitle}
              navigationContent={{
                navIconLabel: l10n.formatText("typeahead.cancel"),
                onClick: onDismiss,
              }}
              toolbarActionContent={
                <ClearButtonTracked moduleName={PROPERTY_FILTERS} rfrr="dialog.clear" onClick={onClickClear}>
                  <UitkText size={400}>{l10n.formatText("typeahead.templates.clear")}</UitkText>
                </ClearButtonTracked>
              }
            />
            <UitkSheetContent padded={false}>
              <UitkSpacing padding={{ blockend: "twelve" }}>
                <UitkLayoutFlex direction="column">
                  <form className="PropertyFiltersDialog" onSubmit={onSubmit}>
                    {props.children}
                    <UitkLayoutPosition
                      position={{ right: "zero", bottom: "zero", left: "zero", zindex: "layer3" }}
                      className="uitk-button-floating-action-fixed"
                    >
                      <UitkScrim type={UitkScrimType.BUTTON}>
                        <UitkFloatingFullWidthButton type="submit" size={UitkButtonSize.LARGE} isFullWidth>
                          {l10n.formatText("datePicker.doneHeader")}
                        </UitkFloatingFullWidthButton>
                      </UitkScrim>
                    </UitkLayoutPosition>
                  </form>
                </UitkLayoutFlex>
              </UitkSpacing>
            </UitkSheetContent>
          </UitkSheet>
        </FiltersDialogComponent>
      </UitkSheetTransition>
    );
  })
);

/**
 * Represents a filter group (e.g. Popular Filters) viewed within a Dialog.
 * @param props
 * @constructor
 */
export const PropertyFilterInDialog = (props: PropertyFiltersGroupProps) => {
  const { filter, withoutHeader, headerSize = 300 } = props;
  const applyFilters = useInteractiveFilters();
  const hasApplyFilters = Boolean(applyFilters);

  if (!filter) {
    return null;
  }

  const { label, name, options, ...otherProps } = filter;

  const Group = PROPERTY_FILTERS_COMPONENT_GROUP_MAP[name];

  const optionsToShow = Array.from(options.values()).filter(
    (option) => !hasApplyFilters || option.label !== "Ocean view"
  );

  return (
    Group &&
    (!withoutHeader ? (
      <div>
        <div role="group" aria-label={label} data-testid={`property-filter-${name}`}>
          <UitkSpacing margin={{ blockend: "two" }}>
            <UitkText size={headerSize} weight="bold">
              {label}
            </UitkText>
          </UitkSpacing>
          <Group
            options={optionsToShow}
            filterLabel={label}
            filterName={name}
            moduleName="PropertyFilters"
            {...otherProps}
          />
        </div>
      </div>
    ) : (
      <Group
        options={optionsToShow}
        filterLabel={label}
        filterName={name}
        moduleName="PropertyFilters"
        {...otherProps}
      />
    ))
  );
};
