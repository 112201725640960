/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react";
import { PropertyFiltersProps, PropertyFiltersFlexModuleResult } from "./typings";
import { Viewport, ViewSmall, ViewLarge } from "@shared-ui/viewport-context";
import { MobilePropertyFilters } from "components/flexComponents/PropertyFilters/viewport/MobilePropertyFilters";
import { DesktopPropertyFilters } from "src/components/flexComponents/PropertyFilters/viewport/DesktopPropertyFilters";
import { usePropertySearchQuery } from "src/stores/PropertyFiltersStore/usePropertySearchQuery";
import { withStores } from "src/stores";
import { observer } from "mobx-react";
import { IntersectionTracker } from "@shared-ui/clickstream-analytics-context";

export const PROPERTY_FILTERS = "PropertyFilters";

export const PropertyFilters = withStores(
  "flexModuleModelStore",
  "propertyFilters",
  "context"
)(
  observer((props: PropertyFiltersProps) => {
    const { templateComponent, context, propertyFilters, flexModuleModelStore } = props;

    const regionId = context.searchContext.location.id;
    propertyFilters.setRegion(regionId);

    if (!templateComponent || !regionId) {
      return null;
    }

    const {
      metadata: { id },
    } = templateComponent;

    const { data, error, loading } = usePropertySearchQuery(context);
    const { view } = templateComponent.config;

    const disableExpando = view === "expanded";

    if (error || loading || !data?.propertySearch.sortAndFilter.options?.sortAndFilter) {
      return <div data-testid="no-property-filters" />;
    }

    if (propertyFilters.isEmpty()) {
      propertyFilters.setFilters(data);
    }
    const model = flexModuleModelStore?.getModel(id) as PropertyFiltersFlexModuleResult | null;

    const daysOffset = model?.daysOffset;

    return (
      <IntersectionTracker referrerId="Hotels.PropertyFilters.impression" trackOnce linkName="Property Filters viewed">
        <div className="PropertyFilters" id={id}>
          <Viewport>
            <ViewSmall>
              <MobilePropertyFilters daysOffset={daysOffset} isExpanded={disableExpando} />
            </ViewSmall>
            <ViewLarge>
              <DesktopPropertyFilters daysOffset={daysOffset} isExpanded={disableExpando} />
            </ViewLarge>
          </Viewport>
        </div>
      </IntersectionTracker>
    );
  })
);

export default PropertyFilters;
