import * as React from "react";
import { PropertyFiltersGroupProps } from "../../typings";
import { withStores } from "stores";
import { UitkExpandoPeek } from "@egds/react-core/expando";
import { UitkHeading } from "@egds/react-core/text";
import { PROPERTY_FILTERS } from "../../PropertyFilters";
import { PropertyFilterInDialog } from "components/flexComponents/PropertyFilters/dialog/PropertyFiltersDialog";
import { useLocalization } from "@shared-ui/localization-context";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { UitkSpacing } from "@egds/react-core/spacing";
import { observer } from "mobx-react";
import { DesktopPropertyFiltersStores, WithApplyProp } from "./types";
import { useInteractiveFilters } from "src/components/flexComponents/PropertyFiltersInteractive/PropertyFiltersInteractiveContext";

type SimplePropertyFiltersProps = Pick<DesktopPropertyFiltersStores, "propertyFilters"> & WithApplyProp;

export const SimplePropertyFilters = withStores("propertyFilters")(
  observer((props: SimplePropertyFiltersProps) => {
    const { propertyFilters, apply } = props;
    const { formatText } = useLocalization();
    const applyFilters = useInteractiveFilters();
    const hasApplyFilters = Boolean(applyFilters);
    const onApply: React.FormEventHandler = (e) => {
      e.preventDefault();
      apply("apply");
    };
    const filters = propertyFilters
      .getListOfFilters()
      .filter((filter) => hasApplyFilters && filter.name !== "paymentType")
      .map((filter, index) => (
        <PropertyFiltersGroup key={`${filter.name}-${index}`} filter={filter} headerSize={300} />
      ));

    return (
      <>
        <UitkSpacing padding={{ blockstart: "two", blockend: "six" }}>
          <UitkHeading tag="h2" size={5}>
            {formatText("propertyfilters.filterBy")}
          </UitkHeading>
        </UitkSpacing>
        <form className="SortAndFilters" onChange={onApply}>
          {filters}
        </form>
      </>
    );
  })
);

/**
 * Represents a filter group (e.g. Popular Filters)
 * @param props
 * @constructor
 */
const PropertyFiltersGroup = (props: PropertyFiltersGroupProps) => {
  const { filter } = props;
  const { formatText } = useLocalization();
  const track = useClickTracker();
  const [isExpandoPeekVisible, setIsExpandoPeekVisible] = React.useState(false);

  const callback = (filterNameAndShowMoreOrLess: string) => {
    const flexTracking: FlexTrackingInfo = {
      moduleName: PROPERTY_FILTERS,
      rfrr: filterNameAndShowMoreOrLess, // e.g. stars.ShowMore
      action: Action.CLICK,
    };

    sendDelayedClickstreamTrackEvent(flexTracking, track);
  };

  return (
    <UitkSpacing padding={{ blockend: "six" }}>
      <UitkExpandoPeek
        lines={5}
        items={5}
        collapseLabel={formatText("propertyfilter.seeLess")}
        expandLabel={formatText("propertyfilter.seeMore")}
        isVisible={isExpandoPeekVisible}
        handleClick={
          /* remove ignore when react-testing-library and jsdom are updated */
          /* istanbul ignore next */ (): void => setIsExpandoPeekVisible((prevIsVisible) => !prevIsVisible)
        }
        analytics={{
          id: filter!.name,
          callback,
        }}
      >
        <PropertyFilterInDialog {...props} />
      </UitkExpandoPeek>
    </UitkSpacing>
  );
};
