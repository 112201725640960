import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { PropertyFiltersGroupProps, PropertyFiltersProps } from "../typings";
import { UitkSecondaryButton } from "@egds/react-core/button";
import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";
import { withStores } from "stores";
import { UitkExpandoPeek } from "@egds/react-core/expando";
import { UitkIcon } from "@egds/react-core/icons";
import { UitkNotificationBadge1 } from "@egds/react-core/badge";
import { PROPERTY_FILTERS } from "../PropertyFilters";
import {
  PropertyFiltersDialog,
  PropertyFilterInDialog,
} from "components/flexComponents/PropertyFilters/dialog/PropertyFiltersDialog";
import { useLocalization } from "@shared-ui/localization-context";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { UitkSpacing } from "@egds/react-core/spacing";
import { observer } from "mobx-react";
import { UitkHeading } from "@egds/react-core/text";
import { SelectGroup } from "components/shared/PropertyFilterComponents/Groupings/SelectGroup";

const ButtonTracked = FlexClickTracker(UitkSecondaryButton);

interface MobilePropertyFiltersProps extends Pick<PropertyFiltersProps, "propertyFilters"> {
  daysOffset?: number;
  isExpanded?: boolean;
}

const MobilePropertyFiltersComponent = (props: MobilePropertyFiltersProps) => {
  const { propertyFilters, daysOffset, isExpanded } = props;
  const { formatText } = useLocalization();
  const [, dialogActions] = useDialog("filters");

  const onClick = () => {
    dialogActions.openDialog();
    propertyFilters.frezze();
  };

  const totalFilter = isExpanded
    ? propertyFilters.getNumberOfSelectedExpandedFilters()
    : propertyFilters.getNumberOfSelectedFilters();

  const btnTitle = propertyFilters.hasSort
    ? formatText("propertyfilters.sortAndFilter")
    : formatText("propertyfilters.filter");

  return (
    <UitkSpacing padding={{ blockend: "two" }}>
      <div>
        <ButtonTracked moduleName={PROPERTY_FILTERS} rfrr="mobileCta" isFullWidth onClick={onClick}>
          <>
            <UitkIcon name="tune" />
            <UitkSpacing margin={{ inline: "two" }}>
              <span>{btnTitle}</span>
            </UitkSpacing>
            {totalFilter > 0 && <UitkNotificationBadge1 text={String(totalFilter)} />}
          </>
        </ButtonTracked>
        <PropertyFiltersDialog daysOffset={daysOffset} isExpanded={isExpanded}>
          <UitkSpacing padding={{ inline: "four", blockstart: "four" }}>
            <div className="SortAndFilters">
              <SortFilter />
              <UitkHeading tag="h3" size={5}>
                {formatText("propertyfilters.filterBy")}
              </UitkHeading>
              {propertyFilters.getListOfFilters().map((filter, index) => (
                <PropertyFiltersGroup key={`${filter.name}-${index}`} filter={filter} isExpanded={isExpanded} />
              ))}
            </div>
          </UitkSpacing>
        </PropertyFiltersDialog>
      </div>
    </UitkSpacing>
  );
};

export const MobilePropertyFilters = React.memo(
  withStores("propertyFilters")(observer(MobilePropertyFiltersComponent))
);

/**
 * Represents a filter group (e.g. Popular Filters)
 * @param props
 * @constructor
 */
const PropertyFiltersGroup = (props: PropertyFiltersGroupProps) => {
  const { filter, isExpanded } = props;
  const { formatText } = useLocalization();
  const track = useClickTracker();
  const [isExpandoPeekVisible, setIsExpandoPeekVisible] = React.useState(false);

  const callback = (filterNameAndShowMoreOrLess: string) => {
    const flexTracking: FlexTrackingInfo = {
      moduleName: PROPERTY_FILTERS,
      rfrr: filterNameAndShowMoreOrLess, // e.g. stars.ShowMore
      action: Action.CLICK,
    };

    sendDelayedClickstreamTrackEvent(flexTracking, track);
  };

  return (
    <UitkSpacing margin={{ block: "six" }}>
      {isExpanded ? (
        <div>
          <PropertyFilterInDialog {...props} />
        </div>
      ) : (
        <UitkExpandoPeek
          lines={3}
          items={3}
          collapseLabel={formatText("propertyfilter.seeLess")}
          expandLabel={formatText("propertyfilter.seeMore")}
          isVisible={isExpandoPeekVisible}
          handleClick={
            /* remove ignore when react-testing-library and jsdom are updated */
            /* istanbul ignore next */ (): void => setIsExpandoPeekVisible((prevIsVisible) => !prevIsVisible)
          }
          analytics={{
            id: filter!.name,
            callback,
          }}
        >
          <PropertyFilterInDialog {...props} />
        </UitkExpandoPeek>
      )}
    </UitkSpacing>
  );
};

interface SortFilterProps extends Pick<PropertyFiltersProps, "propertyFilters"> {}

const SortFilter = withStores("propertyFilters")(
  observer((props: SortFilterProps) => {
    const { propertyFilters } = props;

    if (!propertyFilters.hasSort) {
      return null;
    }

    const sortFilter = propertyFilters.getSort()!;

    return (
      <UitkSpacing padding={{ blockend: "six" }}>
        <div>
          <UitkSpacing padding={{ blockend: "six" }}>
            <div className="uitk-card-separator-bottom">
              <SelectGroup filterName={sortFilter.name} moduleName={PROPERTY_FILTERS} filterLabel={sortFilter.label} />
            </div>
          </UitkSpacing>
        </div>
      </UitkSpacing>
    );
  })
);
