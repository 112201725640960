import * as React from "react";
import { Filter, PropertyFiltersGroupProps, PropertyFiltersProps } from "../../typings";
import { UitkExpandoList, UitkExpandoListItem } from "@egds/react-core/expando";
import { UitkSecondaryButton, UitkFloatingButton } from "@egds/react-core/button";
import { UitkNotificationBadge1 } from "@egds/react-core/badge";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { useLocalization } from "@shared-ui/localization-context";
import { PropertyFilterInDialog } from "../../dialog/PropertyFiltersDialog";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import { PROPERTY_FILTERS } from "components/flexComponents/PropertyFilters/PropertyFilters";
import { UitkScrim, UitkScrimType } from "@egds/react-core/scrim";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkHeading } from "@egds/react-core/text";
import { UitkCard } from "@egds/react-core/cards";
import { DesktopPropertyFiltersStores, WithApplyProp } from "./types";

interface ExpandoPropertyFiltersAdditionalProps {
  isExpanded?: boolean;
}

type ExpandoPropertyFiltersProps = Pick<DesktopPropertyFiltersStores, "propertyFilters"> &
  WithApplyProp &
  ExpandoPropertyFiltersAdditionalProps;

export const ExpandoPropertyFilters = withStores("propertyFilters")(
  observer(({ propertyFilters, apply, isExpanded }: ExpandoPropertyFiltersProps) => {
    const { formatText } = useLocalization();
    const [isApplyButtonHidden, setIsApplyButtonHidden] = React.useState<boolean>(false);

    const numberOfSelectedFilters = isExpanded
      ? propertyFilters.getNumberOfSelectedExpandedFilters()
      : propertyFilters.getNumberOfSelectedFilters();

    const showStickyButton = isApplyButtonHidden && numberOfSelectedFilters > 0;

    const onApply: React.FormEventHandler = (e) => {
      e.preventDefault();
      apply(showStickyButton ? "apply.sticky" : "apply");
    };

    React.useEffect(() => {
      const applyContainer = document.querySelector(".DesktopPropertyFilters .applyContainer");
      if (!applyContainer) return;

      const intersectionObserver = new IntersectionObserver(
        ([e]) => {
          setIsApplyButtonHidden(e.intersectionRatio < 1);
        },
        {
          threshold: [1],
        }
      );

      intersectionObserver.observe(applyContainer);
    }, []);

    const ApplyButton = showStickyButton ? UitkFloatingButton : UitkSecondaryButton;
    const ButtonWrapper = showStickyButton ? UitkScrim : UitkLayoutFlexItem;

    return (
      <UitkSpacing padding={{ blockstart: "four" }}>
        <UitkCard className="DesktopPropertyFilters" overflow>
          <form onSubmit={onApply}>
            <UitkSpacing padding={{ blockstart: "two", blockend: "six", inlinestart: "four" }}>
              <UitkHeading tag="h2" size={5} className="desktopHeader">
                {formatText("propertyfilters.filterBy")}
              </UitkHeading>
            </UitkSpacing>
            {isExpanded ? (
              <UitkSpacing padding={{ inlinestart: "four", inlineend: "two" }}>
                <div>
                  {propertyFilters.getListOfFilters().map((filter, index) => (
                    <UitkSpacing margin={index !== 0 ? { blockstart: "six" } : "unset"} key={`${filter.name}-${index}`}>
                      <div>
                        <PropertyFiltersGroup filter={filter} isExpanded />
                      </div>
                    </UitkSpacing>
                  ))}
                </div>
              </UitkSpacing>
            ) : (
              <UitkSpacing padding={{ inlineend: "two" }}>
                <UitkExpandoList>
                  {propertyFilters.getListOfFilters().map((filter, index) => (
                    <PropertyFiltersGroup key={`${filter.name}-${index}`} filter={filter} />
                  ))}
                </UitkExpandoList>
              </UitkSpacing>
            )}
            <UitkSpacing padding={{ inline: "twelve" }}>
              <UitkLayoutFlex
                className={`applyContainer ${showStickyButton ? "sticky" : ""}`}
                alignItems="center"
                justifyContent="center"
              >
                <UitkLayoutFlexItem>
                  <ButtonWrapper className="applyWrapper" type={UitkScrimType.BUTTON}>
                    <ApplyButton type="submit" className="applyButton" disabled={!numberOfSelectedFilters} isFullWidth>
                      <UitkNotificationBadge1 text={String(numberOfSelectedFilters)} />
                      {formatText("propertyfilters.apply")}
                    </ApplyButton>
                  </ButtonWrapper>
                </UitkLayoutFlexItem>
              </UitkLayoutFlex>
            </UitkSpacing>
          </form>
        </UitkCard>
      </UitkSpacing>
    );
  })
);

/**
 * Represents a filter group (e.g. Popular Filters)
 */
const PropertyFiltersGroup = (props: PropertyFiltersGroupProps) => {
  const { filter, isExpanded } = props;
  const track = useClickTracker();

  const [isOpen, setIsOpen] = React.useState(filter?.name === "popularFilter");
  const [isVisible, setIsVisible] = React.useState(filter?.name === "popularFilter");

  /* istanbul ignore next */
  if (!filter) {
    return null;
  }

  const callback = (filterNameAndShowMoreOrLess: string) => {
    /* istanbul ignore next: */
    if (filterNameAndShowMoreOrLess.includes(".ShowMore")) {
      setIsOpen(true);
    }

    /* istanbul ignore next */
    if (filterNameAndShowMoreOrLess.includes(".ShowLess")) {
      setIsOpen(false);
    }

    const flexTracking: FlexTrackingInfo = {
      moduleName: PROPERTY_FILTERS,
      rfrr: filterNameAndShowMoreOrLess, // e.g. stars.ShowMore
      action: Action.CLICK,
    };
    sendDelayedClickstreamTrackEvent(flexTracking, track);
  };

  return (
    <>
      {isExpanded ? (
        <>
          <PropertyFiltersGroupTitle {...filter} isExpanded={isExpanded} />
          <PropertyFilterInDialog filter={filter} withoutHeader isExpanded={isExpanded} />
        </>
      ) : (
        <UitkExpandoListItem
          expandoTitle={<PropertyFiltersGroupTitle {...filter} isOpen={isOpen} />}
          expandoDescription={<PropertyFilterInDialog filter={filter} withoutHeader />}
          analytics={{
            id: filter.name,
            callback,
          }}
          isVisible={isVisible}
          onToggle={() => setIsVisible((prevIsVisible) => !prevIsVisible)}
          data-testid={filter.name}
        />
      )}
    </>
  );
};

interface PropertyFiltersGroupTitleProps
  extends Pick<Filter, "label" | "name">,
    Pick<PropertyFiltersProps, "propertyFilters"> {
  isOpen?: boolean;
  isExpanded?: boolean;
}

const PropertyFiltersGroupTitle = withStores("propertyFilters")(
  ({ label, name, isOpen, propertyFilters, isExpanded }: PropertyFiltersGroupTitleProps) => {
    /* istanbul ignore next: never called, is validated in a parent component */
    if (!label || !name) {
      return null;
    }

    const totalFilters = isExpanded
      ? propertyFilters.getNumberOfSelectedExpandedFiltersPerCategory(name)
      : propertyFilters.getNumberOfSelectedFiltersPerCategory(name);
    const showBadge = totalFilters > 0 && !isOpen;

    return (
      <UitkLayoutFlex justifyContent="space-between">
        <UitkLayoutFlexItem>
          <div>{label}</div>
        </UitkLayoutFlexItem>
        {showBadge && (
          <UitkLayoutFlexItem>
            <div>
              <UitkNotificationBadge1 text={String(totalFilters)} />
            </div>
          </UitkLayoutFlexItem>
        )}
      </UitkLayoutFlex>
    );
  }
);
