import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { observer } from "mobx-react";
import * as React from "react";
import { useInteractiveFilters } from "src/components/flexComponents/PropertyFiltersInteractive/PropertyFiltersInteractiveContext";
import {
  Action,
  FlexTrackingInfo,
  sendImmediateClickstreamTrackEvent,
} from "src/components/utility/analytics/FlexAnalyticsUtils";
import { withStores } from "src/stores";
import { PROPERTY_FILTERS } from "../../PropertyFilters";
import { ExpandoPropertyFilters } from "./expando";
import { SimplePropertyFilters } from "./simple";
import { DesktopPropertyFiltersStores } from "./types";

interface DesktopPropertyFiltersWrapperProps extends DesktopPropertyFiltersStores {
  daysOffset?: number;
  isExpanded?: boolean;
}

const DesktopPropertyFiltersComponent = (props: DesktopPropertyFiltersWrapperProps) => {
  const { propertyFilters, daysOffset, isExpanded } = props;

  const track = useClickTracker();
  const redirectToSRP = (daysOffset?: number) =>
    window.location.assign(propertyFilters.buildLinkToSRP({ daysOffset, checkForPopularFilterInFilters: isExpanded }));
  const applyFilters = useInteractiveFilters();
  const hasInteractiveFilters = Boolean(applyFilters);

  const apply = (rfrr: string): void => {
    const numberOffiltersApplied = isExpanded
      ? propertyFilters.getNumberOfSelectedExpandedFilters()
      : propertyFilters.getNumberOfSelectedFilters();

    const flexTracking: FlexTrackingInfo = {
      moduleName: PROPERTY_FILTERS,
      rfrr: `${rfrr}.${numberOffiltersApplied}`,
      action: Action.CLICK,
    };

    sendImmediateClickstreamTrackEvent(flexTracking, track);

    if (hasInteractiveFilters) {
      applyFilters!();
    } else {
      redirectToSRP(daysOffset);
    }
  };

  if (hasInteractiveFilters) {
    return <SimplePropertyFilters apply={apply} />;
  }
  return <ExpandoPropertyFilters apply={apply} isExpanded={isExpanded} />;
};

export const DesktopPropertyFilters = withStores(
  "context",
  "propertyFilters"
)(observer(DesktopPropertyFiltersComponent));

export default DesktopPropertyFilters;
